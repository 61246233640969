<template>
  <footer :class="`theme-footer-seven ${dark_bg ? 'dark-bg pt-120 md-pt-100' : 'mt-120 md-mt-100'}`">
    <div class="lg-container">
      <div class="container">
        <div class="row">
          
        </div>
      </div>

      <div class="container">
        <div class="bottom-footer">
          <div class="row">
            <div class="col-lg-4 order-lg-1 mb-20">
              <ul class="d-flex justify-content-center justify-content-lg-start footer-nav">
                <li><a href="#">Privacy & Terms.</a></li>
                <li><a href="#">Contact Us</a></li>
              </ul>
            </div>
            <div class="col-lg-4 order-lg-3 mb-20">
              <ul class="d-flex justify-content-center justify-content-lg-end social-icon">
                <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
              </ul>
            </div>
            <div class="col-lg-4 order-lg-2 mb-20">
              <p class="copyright text-center">Copyright @2022 Cookies-script inc.</p>
            </div>
          </div>
        </div>
      </div>
    </div> <!-- /.lg-container -->

  </footer>
</template>

<script>
export default {
  name: 'FooterSeven',
  props:{
    dark_btn:Boolean,
    dark_bg:Boolean,
  },
  methods: {
    onSubmit() {
      console.log('Submitted');
    },
  },
}
</script>