<template>
    <div class="main-page-wrapper font-gordita overflow-hidden">
      <HeaderFive :doc_signature="true" />
      <ScanningPage />
      <FooterSeven :dark_btn="true" />
    </div>
  </template>
  
  <script>
  import HeaderFive from "../common/Headers/HeaderFive.vue";
  import ScanningPage from "./ScanningPage.vue";
  import FooterSeven from "../common/Footers/FooterSeven.vue";
  export default {
    name: "DocSignatureMain",
    components: {
      HeaderFive,
      ScanningPage,
      FooterSeven,
    },
  };
  </script>
  