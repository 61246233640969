import { createApp } from "vue";
import VueEasyLightbox from "vue-easy-lightbox";
import router from "./routes";
import "bootstrap";
import "./index.css";
import App from "./App.vue";
import axios from 'axios'
import VueAxios from 'vue-axios'

let app = createApp(App);
app.use(router);
app.use(VueEasyLightbox);
app.mount("#app");
app.use(VueAxios, axios)
