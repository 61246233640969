<template>
  <div class="hero-banner-eleven lg-container">
    <div class="container">

      <div v-if="isScanRunning" class="row">
        <div class="col-xl-7 col-lg-6">
          <div class="hero-text-wrapper">
            <h1><strong>
                We are scanning <br />
              </strong>
              <span style="font-weight:lighter">
                {{ this.url }}
              </span>


            </h1>
            <p class="hero-sub-heading">This usually takes about 15 seconds.<br />
              Reports will be shown here when finished.<br />
              You can bookmark this page and visit later.
            </p>

            <br />
            <br />


          </div>
          <!-- /.hero-text-wrapper -->
        </div>
        <!-- End .col -->

        <div class="illustration-container">
          <div class="line"></div>
          <img class="pic" src="../../assets/images/logo/scanning.svg" alt="" />
          <h3 class="start-scanner">Starting scanner...</h3>
          <br />
          <br />
        </div>

      </div>
      <!-- End .row -->
      <div ref="contentToConvert" id="contentToConvert" v-else>
        <div v-if="this.scanResults['cookies_table'] != null ">
        <!-- THE FANCY BOX  -->
        <div class="row justify-content-center">

          <!-- URL BOX HERE -->
          <div class="col-lg-6 col-md-6 pb-4">
            <div class="bg block-style-seventeen">
              <div class="icon d-flex align-items-center justify-content-center">
                <img src="../../assets/images/icon/78.svg" alt="" />
              </div>
              <div class="hover-text">{{ scanResults.url }}</div>
              <div class="static-text">
                <h3>URL : {{ scanResults.url }}</h3>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 pb-4">
            <div class="bg block-style-seventeen cursor" @click="downloadPDF()">
              <div class="icon d-flex align-items-center justify-content-center">
                <img src="../../assets/images/icon/79.svg" alt="" />
              </div>
              <div class="static-text">
                <h3>Download Report</h3>
              </div>
              <div class="hover-text">
                Download Report
              </div>
            </div>
          </div>

        </div>

        <div class="row justify-content-center pb-4">
          <div class="col-12">
            <img class="img-width " :src="scanResults.screenshot" alt="" />
          </div>

        </div>

        <div class="row justify-content-center pb-4">
          <div class="col-12 wrapper">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Cookie Name</th>
                  <th scope="col">Cookie Type</th>
                  <!-- <th scope="col">Platform</th> -->
                  <th scope="col">Before Consent</th>
                  <th scope="col">After Consent</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(value, key, index) in scanResults['cookies_table']" :key="index">
                  <td> {{ key }} </td>
                  <td> {{ value.category }}</td>
                  <!-- <td> {{ value.platform }}</td> -->
                  <td class="mid-center" v-bind:class="beforeConsent(value)">
                    {{ value.without_consent ? '✔' : '' }}
                    {{ beforeConsent(value) == 'orange' ? 'internal check required' : '' }}
                  </td>
                  <td class="center" v-bind:class="afterConsent(value)">
                    {{ value.with_consent ? '✔' : '' }}
                    {{ value.without_consent ? 'cookie is executed without consent' : '' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row justify-content-center">
          <!-- URL BOX HERE -->
          <div class="col-lg-6 col-md-6 pb-4">
            <thead>
              <th class='title-style-six ' scope="col">
                <h2 class="small-fonts">Third party <span>domains</span></h2>
              </th>
            </thead>
            <tr class="list-group-numbered" v-for="(item, index) in scanResults['third_party_domains_all']"
              :key="item.name">
              <td>
                {{ index + 1 }} {{ item }}
              </td>
            </tr>
          </div>

          <div class="col-lg-6 col-md-6 pb-4">
            <thead>
              <th class='title-style-six' scope="col">
                <h2 class="small-fonts">Tracking party <span>domains</span></h2>
              </th>
            </thead>
            <tr class="list-group-numbered" v-for="(item, index) in scanResults['tracking_domains_all']" :key="item.name">
              <td>
                {{ index + 1 }} {{ item }}
              </td>
            </tr>

          </div>


          <!-- TOOLNAME TABLE -->
          <div class="row justify-content-center pb-4">
            <div class="col-12 wrapper">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Tool Name</th>
                    <th scope="col">ID</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(value, key, index) in scanResults['analytics_table']" :key="index">
                    <td> {{ key }} </td>
                    <td> {{ value }}</td>


                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="alert alert-info" role="alert">
        <h1 >check your domain!</h1>
      </div>
        <router-link  class="redo"  to="/">Redo Scan</router-link>
        <button class="refresh"  @click="reloadPage" >Reload Page</button>
      </div>
      </div>
    </div>

  </div>
</template>
  
<script>
import html2pdf from 'html2pdf.js';

const BASE_URL = 'https://us-central1-triple-ranger-383518.cloudfunctions.net'

export default {
  name: "ScanningPage",
  data() {
    return {
      url: '',
      isScanRunning: true,
      scanResults: {},
      // toolName:{},
    };
  },
  components: {},
  methods: {

    async startScanning() {
      this.isScanRunning = true
      
      try {
        const response = await fetch(`${BASE_URL}/start-scanner?url=${this.url}`);
        this.scanResults = await response.json();
        this.isScanRunning = false;
      }
      catch (err) {
        console.log(err)
        this.isScanRunning = false;
      }
    },

    reloadPage() {
           window.parent.location.reload();
        },

    // async ToolName() {
    //   this.isScanRunning = true
    //   try {
    //     const response = await fetch(`${BASE_URL}/start-scanner?url=${this.url}`);
    //     this.toolName = await response.json();
    //     this.isScanRunning = false;
    //   }
    //   catch (err) {
    //     console.log(err)
    //     this.isScanRunning = false;
    //   }
    // },

    async downloadPDF() {
      const element = document.getElementById('contentToConvert'); // Replace 'pdf-content' with the ID of the HTML element you want to convert to PDF

      let name = this.url + '.json'

      await html2pdf().from(element).save(name);


      // const content = this.$refs.contentToConvert;
      // const options = {
      //   margin: 10,
      //   filename: 'downloaded_pdf.pdf',
      //   image: { type: 'jpeg', quality: 0.20 },
      //   html2canvas: { scale: 2, useCORS: true },
      //   jsPDF: { unit: 'in', format: 'a4', orientation: 'p' }
      // };

      // try {
      //   await html2pdf().from(content).set(options).save()
      //   const pdf = await html2pdf().from(content).set(options).outputPdf();
      //   const blob = new Blob([pdf], { type: 'application/pdf' });

      //   // Create a temporary link to download the PDF
      //   const url = URL.createObjectURL(blob);
      //   const link = document.createElement('a');
      //   link.href = url;
      //   link.setAttribute('download', options.filename);
      //   link.click();

      //   // Clean up after the download
      //   URL.revokeObjectURL(url);
      // } catch (error) {
      //   console.error('Error generating PDF:', error);
      // }
    },

    beforeConsent(value) {
      if (value.without_consent) {
        if (value.category == 'Marketing' || value.category == 'Analytics') {
          return 'red'
        } else if (value.category == 'Functional') {
          return 'orange'
        }
      }
      return 'green'
    },
    afterConsent(value) {
      if (value.without_consent) {
        return 'red'
      }
      return 'green'
    },
  },
  mounted() {

    this.url = this.$route.query['query']

    this.startScanning()
    // this.ToolName()
  }
};
</script>
  

<style scoped>
.hero-banner-eleven.lg-container {
  margin-top: 60px;
}

.start-scanner {
  color: #1fcfcf;
  font-weight: 500;
  text-align: center;
}

.img-width {
  width: 73%;
  height: 400px;
  text-align: center;
  border: 3px solid #555;
}

.bg {
  background-color: #c7d7ff;
}

.small-fonts {
  font-size: 47px;
}

.pb-4 {
  padding-bottom: 2.5rem !important;
}

.line {
  top: 140px;
  width: 2px;
  height: 220px;
  background-color: #333;
  position: absolute;
  animation: moveLeftRight 3s infinite;
}

.cursor {
  cursor: pointer;
}

.center {
  text-align: center;
  width: 360px;
}

.redo {
 
  width: 210px;
    font-size: 17px;
    line-height: 56px;
    border: 2px solid #000;
    text-align: center;
    border-radius: 8px;
    font-weight: 500;
    color: #000;
    position: relative;
    transition: all 0.3s ease-in-out;
    left: 560px;
    top: 70px;
}
.redo:hover {
  background: #cae1ec;
  border-color: #cae1ec;
}
.refresh{
  width: 210px;
    font-size: 17px;
    line-height: 56px;
    border: 2px solid #000;
    text-align: center;
    border-radius: 8px;
    font-weight: 500;
    color: #000;
    position: relative;
    transition: all 0.3s ease-in-out;
    left: 560px;
    top: 70px;
    margin-top: 41px;
}
.refresh:hover {
  background: #eef3f9;
  border-color: #eef3f9;
}
.mid-center {
  width: 300px;
}

.red {
  color: red
}

.green {
  color: green
}

.orange {
  color: orange
}

@keyframes moveLeftRight {

  0%,
  100% {
    left: 50px;
  }

  50% {
    left: calc(100% - 100px);
  }
}


@media only screen and (max-width: 600px) {

  .hero-banner-eleven.lg-container {
    margin-top: 20px;
  }

  .line {
    top: 60%;
    height: 185px;
  }

  .small-fonts {
    font-size: 35px;
  }

  .start-scanner {
    margin-top: 30px;
  }

  footer.theme-footer-seven.mt-120.md-mt-100 {
    margin-top: 0 !important;
  }

  .wrapper {
    overflow-x: auto;
    white-space: nowrap;
  }
}

.pic {
  height: 450px
}
</style>