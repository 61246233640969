<template>
  <!-- <HeaderFive :doc_signature="true" /> -->
  <div class="hero-banner-eleven lg-container">
    <div class="container">
      <div class="row">
        <div class="col-xl-7 col-lg-6">
          <div class="hero-text-wrapper">
            <h1>
              Consent Management <br />

              <span><strong>
                  Platform
                </strong>
                <img src="../../assets/images/shape/line-shape-12.svg" alt="" class="cs-screen" />
              </span>

            </h1>
            <p class="hero-sub-heading">All the tools you need to make your website comply with the latest privacy<br />
              regulations, like GDPR, CCPA, LGPD, CNIL. All in one place.</p>
            <h3>Check if your website is compliant</h3>
            <br />
            <form @submit.prevent="onSubmit">
              <input v-model="url" placeholder="https://www.google.com" />

              <button @click="scan()">Scan Cookies</button>
            </form>

          </div>
          <!-- /.hero-text-wrapper -->
        </div>
        <!-- End .col -->

        <div style="padding-top: 140px;" class="illustration-container">
          <img src="../../assets/images/shape/hero.svg" alt="" />
        </div>
      </div>
      <!-- End .row -->
    </div>
    <!-- End .container -->
    <!-- /.partner-slider-two -->
  </div>
</template>

<script>
// import { Carousel, Slide } from "vue3-carousel";

export default {
  name: "HeroBanner",
  data() {
    return {
      logoSliderData: [
        {
          id: 1,
          img: require(`@/assets/images/logo/logo-1.png`),
        },
        {
          id: 2,
          img: require(`@/assets/images/logo/logo-2.png`),
        },
        {
          id: 3,
          img: require(`@/assets/images/logo/logo-3.png`),
        },
        {
          id: 4,
          img: require(`@/assets/images/logo/logo-4.png`),
        },
        {
          id: 5,
          img: require(`@/assets/images/logo/logo-5.png`),
        },
        {
          id: 6,
          img: require(`@/assets/images/logo/logo-2.png`),
        },
        {
          id: 7,
          img: require(`@/assets/images/logo/logo-4.png`),
        },
      ],
      url: '',
      breakpoints: {
        0: {
          itemsToShow: 2,
          snapAlign: "left",
        },
        576: {
          itemsToShow: 3,
          snapAlign: "left",
        },
        768: {
          itemsToShow: 4,
          snapAlign: "left",
        },
        992: {
          itemsToShow: 5,
          snapAlign: "left",
        },
      },
    };
  },
  components: {
    // Carousel,
    // Slide,
  },



  methods: {
    onSubmit() {
      console.log("Submitted");
    },
    scan() {
      if (this.url.length == 0) {
        alert('Please Enter a valid url')
      } else {
        this.$router.push(`scanning-page?query=${this.url}`)
      }
    }
  },
 
  };
</script>
