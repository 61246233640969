<template>
    <ScanningPageMain/>
  </template>
  
  <script>
  import ScanningPageMain from '../components/scan-page';
  
  export default {
    name:'DocSignature',
    components:{
        ScanningPageMain,
    }
  }
  </script>